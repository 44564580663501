function qualifioScriptContact() {
    (function(b,o,n,u,s){var a,t;a=b.createElement(u);a.async=1;a.src=s;t=b.getElementsByTagName(u)[0];t.parentNode.insertBefore(a,t);o[n]=o[n]||[]}) (document,window,'_qual_async','script','//skal.qualifioapp.com/kit/qualp.2.min.js');
    _qual_async.push(['createIframe', 'qualifio_insert_place_869298', 'skal.qualifioapp.com', '20', '064CD7C5-0D2B-4D6B-B6DD-61201FF136F0', '100%', '1200', '', '', '', 'max-width:810px;margin:0 auto;position:relative;z-index:1;']);
}

function qualifioScriptOrder() {
    (function(b,o,n,u,s){var a,t;a=b.createElement(u);a.async=1;a.src=s;t=b.getElementsByTagName(u)[0];t.parentNode.insertBefore(a,t);o[n]=o[n]||[]}) (document,window,'_qual_async','script','//skal.qualifioapp.com/kit/qualp.2.min.js');
    _qual_async.push(['createIframe', 'qualifio_insert_place_869282', 'skal.qualifioapp.com', '20', 'CF20C578-807B-4340-A573-F737ABE6686F', '100%', '1200', '', '', '', 'max-width:810px;margin:0 auto;position:relative;z-index:1;']);
}

function qualifioScriptStory() {
    (function(b,o,n,u,s){var a,t;a=b.createElement(u);a.async=1;a.src=s;t=b.getElementsByTagName(u)[0];t.parentNode.insertBefore(a,t);o[n]=o[n]||[]}) (document,window,'_qual_async','script','//skal.qualifioapp.com/kit/qualp.2.min.js');
    _qual_async.push(['createIframe', 'qualifio_insert_place_863335', 'skal.qualifioapp.com', '20', '861A1327-C0CD-45CF-8165-F5440FD212A8', '100%', '1200', '', '', '', 'max-width:810px;margin:0 auto;position:relative;z-index:1;']);
}

function parallaxScript() {
    document.addEventListener("mousemove", parallax);
    const elem = document.querySelector("#parallax");
    function parallax(e) {
        let _w = window.innerWidth/2;
        let _h = window.innerHeight/2;
        let _mouseX = e.clientX;
        let _mouseY = e.clientY;
        let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
        let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`;
        let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`;
        let x = `${_depth3}, ${_depth2}, ${_depth1}`;
        console.log(x);
        elem.style.backgroundPosition = x;
    }
}